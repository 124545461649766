import { Add } from '@mui/icons-material';
import MomentUtils from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Button, InputLabel, TextField, Typography } from '@mui/material';
import { Loader } from 'components';
import ContentMultiple from 'components/ContentMultiple/ContentMultiple';
import InputDate from 'components/InputDate';
import Select from 'components/Select';
import SelectUser from 'components/SelectUser';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import {
  canEveryDeliverableHaveDateChange,
  isEveryDeliverableCustom,
  isEveryDeliverableNotFromQuote,
  isEveryDeliverableUnsubmitted,
} from 'features/Deliverables/services/deliverableService';
import useUserRoles from 'hooks/useUserRoles';
import { pickBy } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDrawerAction } from 'store/actions/drawerActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import {
  selectScopes,
  selectScopesForTnM,
  selectAccountablesForTnM,
  selectConsultantsForTnM,
  selectClientsForTnM,
} from 'store/selectors/deliverableSelectors';
import Order from 'types/entities/Order';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import Scope from 'types/entities/Scope';
import User from 'types/entities/User';
import { OrderWorkunitOrigin } from 'types/enums/OrderWorkunitOrigin';
import { OrderWorkunitsLabel } from 'types/enums/OrderWorkunitsLabel';

type DeliverableEditProps = {
  ids: OrderWorkunit['id'][];
};

const DeliverableEdit: React.FC<DeliverableEditProps> = ({ ids }) => {
  const { t } = useTranslation();
  const { editDeliverables, getDeliverableByListId, isTnM, fetchDeliverables } = useDeliverableService();
  const [updateDateReason, setUpdateDateReason] = useState<string | null>(null);
  const [isChangeDateTextareaOpen, setIsChangeDateTextareaOpen] = useState(false);
  const { dispatch } = useAppState();
  const state = useSelector((state) => state.deliverable);
  const loadings = useSelector((state) => state.loadings);
  const [dateValid, setDateValid] = useState<boolean>(true);
  const userRoles = useUserRoles();
  let scopes = useSelector(selectScopes);
  const tmScopes = useSelector(selectScopesForTnM);
  if (scopes.length === 0) {
    scopes = tmScopes;
  }
  const tmAccountables = useSelector(selectAccountablesForTnM);
  const tmConsultants = useSelector(selectConsultantsForTnM);
  const accountables = isTnM() ? tmAccountables : state.deliveryManagers;
  const consultants = isTnM() ? tmConsultants : state.consultants;
  const clients = state.customers;

  const [paramsForEdition, setParamsForEdition] = useState<Partial<OrderWorkunit>>({});

  const [orderWorkunits, setOrderWorkunits] = useState<OrderWorkunit[]>([]);

  const getSelectedDeliverableByListId = useCallback(async () => {
    const deliverables = await getDeliverableByListId(state?.order?.id as number, ids);
    if (deliverables?.length === 1) {
      setParamsForEdition(() => ({
        ...paramsForEdition,
        client: deliverables[0].client,
        scope: deliverables[0].scope,
        consultant: deliverables[0].consultant,
        delivery_manager: deliverables[0].delivery_manager,
        content2: deliverables[0].content2,
        content3: deliverables[0].content3,
        content4: deliverables[0].content4,
        content5: deliverables[0].content5,
        purchase_order: deliverables[0].purchase_order,
      }));
    }
    if (deliverables?.length > 1) {
      const allClientsSame = deliverables.every((deliverable) => deliverable.client_id === deliverables[0].client_id);
      const allScopesSame = deliverables.every((deliverable) => deliverable.scope?.id === deliverables[0].scope?.id);
      const allConsultantsSame = deliverables.every(
        (deliverable) => deliverable.consultant?.id === deliverables[0].consultant?.id
      );
      const allDeliveryManagerSame = deliverables.every(
        (deliverable) => deliverable.delivery_manager[0]?.id === deliverables[0].delivery_manager[0]?.id
      );
      const allContentsSame = deliverables.every((deliverable) => deliverable.content === deliverables[0].content);
      const allContent2Same = deliverables.every((deliverable) => deliverable.content2 === deliverables[0].content2);
      const allContent3Same = deliverables.every((deliverable) => deliverable.content3 === deliverables[0].content3);
      const allContent4Same = deliverables.every((deliverable) => deliverable.content4 === deliverables[0].content4);
      const allContent5Same = deliverables.every((deliverable) => deliverable.content5 === deliverables[0].content5);
      const allPurchaseOrderSame = deliverables.every(
        (deliverable) => deliverable.purchase_order === deliverables[0].purchase_order
      );

      setParamsForEdition(() => ({
        ...paramsForEdition,
        client: allClientsSame ? deliverables[0].client : undefined,
        scope: allScopesSame ? deliverables[0].scope : undefined,
        consultant: allConsultantsSame ? deliverables[0].consultant : undefined,
        delivery_manager: allDeliveryManagerSame ? deliverables[0].delivery_manager : undefined,
        purchase_order: allPurchaseOrderSame ? deliverables[0].purchase_order : undefined,
        content: allContentsSame ? deliverables[0].content : undefined,
        content2: allContent2Same ? deliverables[0].content2 : undefined,
        content3: allContent3Same ? deliverables[0].content3 : undefined,
        content4: allContent4Same ? deliverables[0].content4 : undefined,
        content5: allContent5Same ? deliverables[0].content5 : undefined,
      }));
    }
    setOrderWorkunits(() => deliverables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDeliverableByListId, ids, state?.order?.id]);

  useEffect(() => {
    getSelectedDeliverableByListId();
  }, [getSelectedDeliverableByListId, ids, state?.order?.id]);

  const submit = async (e?: { preventDefault: () => void }) => {
    e?.preventDefault();
    if (orderWorkunits)
      try {
        const updateWithReason: OrderWorkunit['id'][] = [];
        const updateWithoutReason: OrderWorkunit['id'][] = [];
        let cleanedObject = pickBy(paramsForEdition, (v) => v !== null);
        cleanedObject = Object.entries(cleanedObject).reduce((acc, [key, value]) => {
          if (value === 0 && key.endsWith('_id')) {
            const name = key.replace('_id', '');
            const fullNameKey = `${name}_fullname`;
            acc = {
              ...acc,
              [name]: null,
              [fullNameKey]: null,
              [key]: null,
            };
          } else {
            acc = {
              ...acc,
              [key]: value,
            };
          }
          return acc;
        }, {});
        if (paramsForEdition.consultant_id) {
          cleanedObject.consultant_id = paramsForEdition.consultant_id;
        }
        orderWorkunits.forEach((owu) => {
          if (
            owu.origin === OrderWorkunitOrigin.QUOTE &&
            !owu.label?.includes(OrderWorkunitsLabel.UPDATED) &&
            updateDateReason
          ) {
            updateWithReason.push(owu.id);
          } else {
            updateWithoutReason.push(owu.id);
          }
        });
        if (updateWithReason.length > 0 && !state.selectedAllDeliverables) {
          await editDeliverables(
            cleanedObject,
            updateWithReason,
            state?.order?.id as Order['id'],
            state.selectedAllDeliverables,
            updateDateReason ?? undefined
          );
        }
        if (updateWithoutReason.length > 0 && !state.selectedAllDeliverables) {
          await editDeliverables(
            cleanedObject,
            updateWithoutReason,
            state?.order?.id as Order['id'],
            state.selectedAllDeliverables
          );
        }
        if (state.selectedAllDeliverables) {
          await editDeliverables(
            cleanedObject,
            orderWorkunits.map((orderWorkunit) => orderWorkunit.id),
            state?.order?.id as Order['id'],
            state.selectedAllDeliverables
          );
        }
      } catch (error) {
        dispatch(
          setSnackbarAction({
            message: 'Error while updating workunit',
            open: true,
            severity: 'error',
          })
        );
      } finally {
        fetchDeliverables(state?.order?.id, { current: null });
        dispatch(closeDrawerAction());
      }
  };

  const handleDateChange = (date: Moment | null, name: string) => {
    if (date) {
      if (!date.isValid()) setDateValid(false);
      if (date.isValid()) setDateValid(true);
      setParamsForEdition({
        ...paramsForEdition,
        [name]: moment(date).format('YYYY-MM-DD'),
      });
    } else {
      setDateValid(true);
      setParamsForEdition({
        ...paramsForEdition,
        [name]: undefined,
      });
    }
  };

  const everyDeliverableIsNotFromQuote =
    isEveryDeliverableNotFromQuote(orderWorkunits) || state.selectedAllDeliverables;
  const everyDeliverableIsCustom = isEveryDeliverableCustom(orderWorkunits) || state.selectedAllDeliverables;

  useEffect(() => {
    const forecastDate: string | undefined = paramsForEdition.forecast_date as string | undefined;

    const isForecastDateChanged = (): boolean =>
      forecastDate !== undefined && orderWorkunits && orderWorkunits[0].forecast_date !== forecastDate;
    let isChangeDateTextareaOpen = false;

    if (everyDeliverableIsNotFromQuote && !state.selectedAllDeliverables) {
      isChangeDateTextareaOpen = false;
    } else if (ids.length === 1 && orderWorkunits && !state.selectedAllDeliverables) {
      isChangeDateTextareaOpen = isForecastDateChanged();
    } else if (orderWorkunits && !state.selectedAllDeliverables) {
      isChangeDateTextareaOpen = isForecastDateChanged();
    } else if (state.selectedAllDeliverables && forecastDate !== undefined) {
      isChangeDateTextareaOpen = true;
    }

    setIsChangeDateTextareaOpen(() => isChangeDateTextareaOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [everyDeliverableIsNotFromQuote, orderWorkunits, paramsForEdition]);

  const getDefaultDisplayedValue = useCallback(
    (
      field: keyof Pick<
        OrderWorkunit,
        | 'workunit_name'
        | 'workunit_reference'
        | 'content'
        | 'delivery_date'
        | 'forecast_date'
        | 'content2'
        | 'workunit_desc'
        | 'purchase_order'
      >
    ): string => {
      if (orderWorkunits.length === 1 && !paramsForEdition[field] && orderWorkunits[0][field])
        return orderWorkunits[0][field] ?? '';
      if (paramsForEdition[field]) return paramsForEdition[field] ?? '';
      return '';
    },
    [orderWorkunits, paramsForEdition]
  );

  const getDefaultScope = useCallback(() => {
    if (orderWorkunits.length === 1) return orderWorkunits[0].scope;
    return undefined;
  }, [orderWorkunits]);

  const [autorization, setAutorization] = useState({
    everyDeliverableIsUnsubmitted: false,
    everyDeliverableCanHaveDateChange: false,
  });

  const everyDeliverableIsUnsubmitted = useCallback(
    () => isEveryDeliverableUnsubmitted(orderWorkunits),
    [orderWorkunits]
  );
  const everyDeliverableCanHaveDateChange = useCallback(
    () => canEveryDeliverableHaveDateChange(orderWorkunits),
    [orderWorkunits]
  );

  useEffect(() => {
    setAutorization(() => ({
      everyDeliverableIsUnsubmitted: everyDeliverableIsUnsubmitted() || state.selectedAllDeliverables,
      everyDeliverableCanHaveDateChange: everyDeliverableCanHaveDateChange() || state.selectedAllDeliverables,
    }));
  }, [everyDeliverableCanHaveDateChange, everyDeliverableIsUnsubmitted]);

  return (
    <form onSubmit={submit} className="deliverables-edit-container">
      {orderWorkunits?.length === 1 && !state.selectedAllDeliverables && (
        <Box sx={{ backgroundColor: 'primary.light', borderRadius: '10px', p: 3, mb: 3 }}>
          <Box sx={{ mb: 1 }}>
            <Typography>{t('Scope_name')}</Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontWeight: 500,
              }}
            >
              {orderWorkunits[0]?.scope?.name ? orderWorkunits[0]?.scope?.name : t('No_scope')}
            </Typography>
          </Box>
          {orderWorkunits[0].workunit_reference && (
            <Box sx={{ mb: 1 }}>
              <Typography>{t('REFERENCE_OF_WORKUNIT')}</Typography>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                }}
              >
                {orderWorkunits[0].workunit_reference}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography>{t('Delivery_date')}</Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontWeight: 500,
              }}
            >
              {orderWorkunits[0].delivery_date ||
                moment(orderWorkunits[0].forecast_date).format('DD-MM-YYYY') ||
                t('Not_defined')}
            </Typography>
          </Box>
          {orderWorkunits[0].workunit_desc && (
            <Box sx={{ mb: 1 }}>
              <Typography>{t('DESCRIPTION_NEW_WORKUNIT')}</Typography>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                }}
              >
                {orderWorkunits[0].workunit_desc}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {loadings.api ? (
        <Loader />
      ) : (
        <>
          {everyDeliverableIsCustom && (
            <>
              <Box sx={{ mb: 3 }}>
                <InputLabel htmlFor="mission_name">{t('Name')}</InputLabel>
                <TextField
                  name="workunit_name"
                  id="mission_name"
                  className="deliverables-input active-blue"
                  placeholder={t('Name_deliverable')}
                  onChange={(e) => setParamsForEdition({ ...paramsForEdition, [e.target.name]: e.target.value })}
                  value={paramsForEdition.workunit_name ?? getDefaultDisplayedValue('workunit_name')}
                  required
                  fullWidth
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <InputLabel htmlFor="mission_ref">{t('Reference')}</InputLabel>
                <TextField
                  name="workunit_reference"
                  id="mission_ref"
                  className="deliverables-input active-blue"
                  placeholder={t('Deliverable_Reference')}
                  onChange={(e) => setParamsForEdition({ ...paramsForEdition, [e.target.name]: e.target.value })}
                  required
                  value={paramsForEdition.workunit_reference ?? getDefaultDisplayedValue('workunit_reference') ?? ''}
                  fullWidth
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <InputLabel htmlFor="workunit_desc">{t('Description')}</InputLabel>
                <TextField
                  name="workunit_desc"
                  id="workunit_desc"
                  rows={4}
                  className="active-blue background-grey no-border deliverables-input "
                  onChange={(e) => setParamsForEdition({ ...paramsForEdition, [e.target.name]: e.target.value })}
                  required
                  placeholder={t('Deliverable_Description')}
                  value={paramsForEdition.workunit_desc ?? getDefaultDisplayedValue('workunit_desc') ?? ''}
                  fullWidth
                  multiline
                />
              </Box>
            </>
          )}
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="content">{t('Content')}</InputLabel>
            <TextField
              name="content"
              id="content"
              rows={4}
              className="active-blue no-border deliverables-input "
              value={paramsForEdition.content ?? getDefaultDisplayedValue('content') ?? ''}
              required
              onChange={(e) =>
                setParamsForEdition({ ...paramsForEdition, [e.target.name]: e.target.value ? e.target.value : ' ' })
              }
              placeholder={t('Deliverable_Content')}
              multiline
              fullWidth
            />
          </Box>

          {paramsForEdition && (
            <ContentMultiple orderWorkunit={paramsForEdition as OrderWorkunit} onchange={setParamsForEdition} />
          )}

          {everyDeliverableIsCustom && (
            <LocalizationProvider dateAdapter={MomentUtils}>
              <Box sx={{ mb: 3 }}>
                <InputDate
                  label={t('Initial_Deliverable_date')}
                  name="delivery_date"
                  id="delivery_date"
                  placeholder="DD/MM/YYYY"
                  onchange={(name, date) => handleDateChange(date, name)}
                  value={paramsForEdition.delivery_date ?? getDefaultDisplayedValue('delivery_date')}
                />
              </Box>
            </LocalizationProvider>
          )}
          <LocalizationProvider dateAdapter={MomentUtils}>
            <Box sx={{ mb: 3 }}>
              <InputDate
                label={t('New_expected_date')}
                name="forecast_date"
                id="forecast_date"
                placeholder="DD/MM/YYYY"
                showError
                canEmpty
                disabled={!everyDeliverableCanHaveDateChange()}
                onchange={(name, date) => handleDateChange(date, name)}
                value={paramsForEdition.forecast_date ?? getDefaultDisplayedValue('forecast_date')}
                min={moment(state.order?.start_date).toString()}
                max={moment(state.order?.end_date).add('1', 'year').toString()}
              />
            </Box>
            {isChangeDateTextareaOpen && (
              <Box sx={{ mb: 3 }}>
                <InputLabel htmlFor="cancel-reason-input">{t('Comment your modifications')}</InputLabel>
                <TextField
                  name="cancel-reason"
                  id="cancel-reason-input"
                  rows={4}
                  value={updateDateReason ?? ''}
                  required
                  onChange={(e) => {
                    setUpdateDateReason(e.target.value);
                    setParamsForEdition(() => ({
                      ...paramsForEdition,
                      modification_reason: e.target.value,
                    }));
                  }}
                  placeholder={t('Enter your text here')}
                  multiline
                  fullWidth
                />
              </Box>
            )}
          </LocalizationProvider>
          {isTnM() && (
            <Box sx={{ mb: 3 }}>
              <InputLabel htmlFor="purchase_order">{t('purchase_order')}</InputLabel>
              <TextField
                name="purchase_order"
                id="purchase_order"
                placeholder={t('purchase_order')}
                onChange={(e) => setParamsForEdition({ ...paramsForEdition, [e.target.name]: e.target.value })}
                value={paramsForEdition.purchase_order ?? getDefaultDisplayedValue('purchase_order')}
                required
                fullWidth
              />
            </Box>
          )}
          <Box sx={{ mb: 3 }}>
            {scopes && (
              <Select
                options={scopes}
                selectedOption={paramsForEdition?.scope ?? getDefaultScope() ?? undefined}
                optionsLabels="name"
                label={t('Scopes')}
                handleSelectOptions={(opt) =>
                  setParamsForEdition({
                    ...paramsForEdition,
                    scope_id: opt?.id,
                    scope: opt as Scope | undefined,
                  })
                }
                propertyName="scope"
                required={false}
                disabled={!(everyDeliverableIsNotFromQuote && autorization.everyDeliverableIsUnsubmitted)}
              />
            )}
          </Box>
          <Box sx={{ mb: 3 }}>
            {!userRoles.isConsultant && (
              <>
                {!consultants ? (
                  <Loader size={20} />
                ) : (
                  <>
                    <SelectUser
                      users={consultants}
                      selectedUser={paramsForEdition?.consultant ?? null}
                      onSelectUser={(value) => {
                        if (value)
                          setParamsForEdition({
                            ...paramsForEdition,
                            consultant_id: value?.id,
                            consultant: value as User | undefined,
                          });
                        // else
                        //   setParamsForEdition({
                        //     ...paramsForEdition,
                        //     consultant_id: 0,
                        //     consultant: null,
                        //   });
                      }}
                      label={t('Consultant')}
                      required={ids?.length === 1}
                      disabled={userRoles.isClient || !autorization.everyDeliverableIsUnsubmitted}
                    />
                    {userRoles.isClient ? null : (
                      <>
                        {!autorization.everyDeliverableIsUnsubmitted &&
                          t('To change the consultant, cancel the submission')}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
          <Box sx={{ mb: 3 }}>
            {!accountables ? (
              <Loader size={20} />
            ) : (
              <>
                <SelectUser
                  users={accountables}
                  selectedUser={
                    paramsForEdition?.delivery_manager?.length ? paramsForEdition?.delivery_manager[0] : null
                  }
                  onSelectUser={(value) => {
                    if (value)
                      setParamsForEdition({
                        ...paramsForEdition,
                        delivery_manager_ids: [value?.id],
                        delivery_manager: [value],
                      });
                    // else
                    //   setParamsForEdition({
                    //     ...paramsForEdition,
                    //     delivery_manager_ids: [],
                    //     delivery_manager: [],
                    //   });
                  }}
                  label={t('Delivery_manager')}
                  required={ids?.length === 1}
                  disabled={userRoles.isClient || !autorization.everyDeliverableIsUnsubmitted}
                />
                {userRoles.isClient ? null : (
                  <>
                    {!autorization.everyDeliverableIsUnsubmitted &&
                      t('To change the consultant, cancel the submission')}
                  </>
                )}
              </>
            )}
          </Box>
          <Box sx={{ mb: 3 }}>
            {!clients ? (
              <Loader size={20} />
            ) : (
              <>
                <SelectUser
                  users={clients}
                  selectedUser={paramsForEdition?.client ?? null}
                  onSelectUser={(value) => {
                    if (value)
                      setParamsForEdition({
                        ...paramsForEdition,
                        client_id: value?.id,
                        client: value as User | undefined,
                      });
                    // else
                    //   setParamsForEdition({
                    //     ...paramsForEdition,
                    //     client_id: 0,
                    //     client: null,
                    //   });
                  }}
                  label={t('Client')}
                  required={ids?.length === 1}
                  disabled={userRoles.isClient || !autorization.everyDeliverableIsUnsubmitted}
                />
                {userRoles.isClient ? null : (
                  <>
                    {!autorization.everyDeliverableIsUnsubmitted &&
                      t('To change the consultant, cancel the submission')}
                  </>
                )}
              </>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={submit}
              disabled={
                (isChangeDateTextareaOpen && !updateDateReason) ||
                // (isChangeDateTextareaOpen && !updateDateReason) ||
                !dateValid ||
                !paramsForEdition?.consultant ||
                !paramsForEdition?.delivery_manager?.length ||
                !paramsForEdition?.client ||
                // !paramsForEdition?.consultant ||
                // !paramsForEdition?.delivery_manager?.length ||
                // !paramsForEdition?.client ||
                !!loadings.api
              }
              startIcon={<Add />}
              variant="contained"
              color="success"
            >
              {t('Validate_the_modifications')}
            </Button>
          </Box>
        </>
      )}
    </form>
  );
};

export default DeliverableEdit;
